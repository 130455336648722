<template>
  <validation-observer ref="deviceRules">
    <b-row>
      <b-col cols="12">
        <b-card title="Device Details">
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Device Name"
                label-for="v-devicename"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Device Name"
                  rules="required"
                >
                  <b-form-input
                    id="v-devicename"
                    v-model="name"
                    placeholder="Windspeed 101"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Business"
                label-for="v-business"
              >
                <validation-provider
                  #default="{ errors }"
                  name="business"
                  rules="required"
                >
                  <b-form-select
                    id="v-business"
                    v-model="business"
                    :options="businesses"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Enabled"
                label-for="v-enabled"
              >
                <b-form-checkbox
                  id="v-enabled"
                  v-model="enabled"
                  class="custom-control-info"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-success"
            class="mt-0 mt-md-2 float-right"
            :disabled="loading"
            @click.prevent="validationForm"
          >
            <b-spinner
              small
              :hidden="isLoading"
            />
            <feather-icon
              icon="CheckIcon"
              class="mr-25"
            />
            <span>Add</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2 mr-2 float-right"
            :disabled="loading"
            @click="cancel()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>Cancel</span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BCard,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@/@core/mixins/ui/transition'
import store from '@store';

import axios from 'axios';
import * as api from '@api';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BCard,
    BButton,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      name: '',
      business: null,
      enabled: true,
      loading: false,
      required,
      businesses: [{
        value: null, text: 'Select a business', disabled: true,
      }],
    }
  },
  computed: {
    isLoading() {
      return !this.loading;
    },
  },
  async created() {
    await store.dispatch('getBusinesses');
    store.state.businesses.businesses.forEach(b => {
      this.businesses.push({
        value: b.id,
        text: b.name,
      });
    });
  },
  methods: {
    async cancel() {
      this.$router.go(-1);
    },
    validationForm() {
      this.$refs.deviceRules.validate().then(success => {
        if (success) {
          this.formSubmitted();
        }
      })
    },
    async formSubmitted() {
      try {
        this.loading = true;
        const businessUpdate = {
          name: this.name,
          enabled: this.enabled,
        };

        const result = await axios({
          method: 'post',
          url: api.business,
          data: businessUpdate,
        });

        if (result.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Business Created',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        } else {
          throw new Error();
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      } finally {
        this.$router.push('/businesses');
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '~@core/assets/fonts/feather/iconfont.css';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-good-table.scss';
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>
